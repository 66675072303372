import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Hero } from "../../common/Hero";
import { BlockContent } from "../../common/BlockContent";
import { ImageMediaGrid } from "../../common/ImageMediaGrid";
import { Grid } from "../../common/Grid";

import * as styles from "./Artwork.module.scss";

const Artwork = ({ artwork }) => {
  const date = artwork.artworkDate
    ? new Date(artwork.artworkDate).getFullYear()
    : null;

  const width = artwork.dimensions ? artwork.dimensions.width || null : null;
  const depth = artwork.dimensions ? artwork.dimensions.depth || null : null;
  const height = artwork.dimensions ? artwork.dimensions.height || null : null;
  const units = artwork.dimensions ? artwork.dimensions.units || null : null;

  const dims = width || depth || height ? true : null;

  const relatedBiography = artwork.relatedBiography;
  const sortedRelatedBiography = relatedBiography.sort((a, b) =>
    a.biography.startDate > b.biography.startDate ? -1 : 1
  );

  return (
    <article>
      <Hero image={artwork.mainImage.asset.gatsbyImageData} alt="" />
      <h1>{artwork.title}</h1>
      <div className={styles.info}>
        <section className={styles.details}>
          {date && <h3 className={styles.date}>{date}</h3>}
          <div className={styles.material}>{artwork.material}</div>
          <div className={styles.dimensions}>
            {width && (
              <>
                {width}
                {units}
              </>
            )}
            {depth && (
              <>
                {" x "}
                {depth}
                {units}
              </>
            )}
            {height && (
              <>
                {" x "}
                {height}
                {units}
              </>
            )}
            {dims && (
              <>
                {" "}
                ({width && <>w</>}
                {depth && <> x d</>}
                {height && <> x h</>})
              </>
            )}
          </div>
          {artwork.links?.length > 0 && (
            <div className={styles.links}>
              <h3>Links:</h3>
              {artwork.links.map((link, index) => (
                <div className={styles.link} key={index}>
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                  >
                    {link.title}
                  </a>
                </div>
              ))}
            </div>
          )}

          {artwork.downloads.length > 0 && (
            <>
              <h3>Downloads</h3>
              {artwork.downloads.map((download, index) => (
                <div className={styles.link} key={index}>
                  <a
                    href={download.asset.url}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                  >
                    {download.title && download.title}
                    {download.description && (
                      <>
                        <br />
                        {download.description}
                      </>
                    )}
                  </a>
                </div>
              ))}
            </>
          )}
        </section>
        <section className={styles.description}>
          <BlockContent
            emergencyAlt={artwork.title}
            blocks={artwork._rawDescription || []}
          />
        </section>
      </div>

      <section>
        <ImageMediaGrid items={artwork.artworks} defaultAlt={artwork.title} />
      </section>

      {sortedRelatedBiography.length > 0 && (
        <section className={styles.relatedArtworks}>
          <h3>Exhibitions</h3>
          <Grid
            items={sortedRelatedBiography.map((item) => item.biography)}
            type="biography"
          />
        </section>
      )}
      {artwork.relatedArtworks.length > 0 && (
        <section className={styles.relatedArtworks}>
          <h3>Related Artworks</h3>
          <Grid
            items={artwork.relatedArtworks.map((item) => item.artwork)}
            type="artwork"
          />
        </section>
      )}
    </article>
  );
};

export { Artwork };
